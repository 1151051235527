import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const DeviceManagements = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Device Managements");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "name",
      //   type: "text",
      //   placeholder: "Name",
      //   label: "Name",
      //   header: "Access Point Name",
      //   width: 110,
      //   id: "name",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   controllerName: null,
      //   fieldType: "Link",
      //   textAlign: "Center",
      //   displayinregisterForm: false,
      //   isBulkUploadField: true,
      //   globalSearchField: "true",
      //   show: true,
      //   field: "name",
      //   isFieldRequired: "false",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      // {
      //   name: "createdByName",
      //   type: "text",
      //   placeholder: "Created By",
      //   label: "Created By",
      //   width: 120,
      //   header: "Created By",
      //   id: "createdByName",
      //   displayinlist: "true",
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   fieldType: "Text",
      //   textAlign: "Center",
      //   displayinregisterForm: false,
      //   isBulkUploadField: false,
      //   show: true,
      //   field: "createdByName",
      //   mobile: true,
      //   filter: true,
      //   displayInSettings: true,
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   isFieldRequired: "false",
      //   tDisplay: true,
      // },
      // {
      //   name: "updatedByName",
      //   type: "text",
      //   placeholder: "Updated By",
      //   label: "Updated By",
      //   width: 120,
      //   header: "Updated By",
      //   id: "updatedByName",
      //   displayinlist: "true",
      //   fieldType: "Text",
      //   textAlign: "Center",
      //   displayinregisterForm: false,
      //   globalSearchField: "false",
      //   isBulkUploadField: false,
      //   show: true,
      //   filter: true,
      //   field: "updatedByName",
      //   mobile: true,
      //   displayInSettings: true,
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   isFieldRequired: "false",
      //   tDisplay: true,
      // },
      // {
      //   name: "created",
      //   type: "date",
      //   placeholder: "Created",
      //   label: "Created",
      //   width: 90,
      //   header: "Created",
      //   derivedValue: "created=undefined",
      //   actions: [],
      //   actionsNumber: [],
      //   id: "created",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   displayinlist: "true",
      //   globalSearchField: "false",
      //   controllerId: null,
      //   fieldType: "Date",
      //   dateFormat: "YYYY-MM-DD HH:mm:ss",
      //   textAlign: "Center",
      //   isBulkUploadField: false,
      //   disabled: true,
      //   show: true,
      //   field: "created",
      //   isFieldRequired: "false",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      // {
      //   name: "updated",
      //   type: "date",
      //   placeholder: "Updated",
      //   label: "Updated",
      //   width: 90,
      //   header: "Updated",
      //   derivedValue: "updated=undefined",
      //   actions: [],
      //   actionsNumber: [],
      //   id: "updated",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   displayinlist: "true",
      //   globalSearchField: "false",
      //   controllerId: null,
      //   fieldType: "Date",
      //   dateFormat: "YYYY-MM-DD HH:mm:ss",
      //   textAlign: "Center",
      //   isBulkUploadField: false,
      //   disabled: true,
      //   show: true,
      //   field: "updated",
      //   isFieldRequired: "false",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
     
      {
        name: "device_id",
        fieldName: "device_id",
        type: "text",
        placeholder: "Device Id",
        value: "",
        label: "Device Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Device Id",
        derivedValue: "device_id=device_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "device_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "device_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        // fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      // {
      //   name: "deviceName",
      //   fieldName: "deviceName",
      //   type: "text",
      //   placeholder: "Device Name",
      //   value: "",
      //   label: "Device Name",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Device Name",
      //   derivedValue: "deviceName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "deviceName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "center",
      //   show: true,
      //   field: "deviceName",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      // {
      //   name: "deviceLocation",
      //   fieldName: "deviceLocation",
      //   type: "text",
      //   placeholder: "Device Location",
      //   value: "",
      //   label: "Device Location",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Device Location",
      //   derivedValue: "deviceLocation=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "deviceLocation",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "center",
      //   show: true,
      //   field: "deviceLocation",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "mac_id",
        fieldName: "mac_id",
        type: "text",
        placeholder: "Mac Id",
        value: "",
        label: "Mac Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Mac Id",
        derivedValue: "mac_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "mac_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "mac_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "facilityNameSearch",
        fieldName: "facilityNameSearch",
        type: "text",
        placeholder: "facilityNameSearch",
        value: "",
        label: "facilityNameSearch",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "facility Name",
        derivedValue: "facilityNameSearch=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "facilityNameSearch",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "facilityNameSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "storageUnitNameSearch",
        fieldName: "storageUnitNameSearch",
        type: "text",
        placeholder: "storageUnitNameSearch",
        value: "",
        label: "storageUnitNameSearch",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Storage Unit Name",
        derivedValue: "storageUnitNameSearch=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "storageUnitNameSearch",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "storageUnitNameSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
     
      // {
      //   name: "location_id",
      //   fieldName: "location_id",
      //   type: "text",
      //   placeholder: "Location Id",
      //   value: "",
      //   label: "Location Id",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Location Id",
      //   derivedValue: "location_id=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "location_id",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "center",
      //   show: true,
      //   field: "location_id",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      // {
      //   name: "locationName",
      //   fieldName: "locationName",
      //   type: "text",
      //   placeholder: "Location Name",
      //   value: "",
      //   label: "Location Name",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Location Name",
      //   derivedValue: "locationName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "locationName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "center",
      //   show: true,
      //   field: "locationName",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "type",
        fieldName: "type",
        type: "radio",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Type",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [],
        fieldType: "radio",
        filterElement: [
          { label: "Cart", value: "cart", id: "car" },
          { label: "Others", value: "others", id: "oth" },
        ],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "expirationDate",
        fieldName: "expirationDate",
        type: "text",
        placeholder: "Expiration Date",
        value: "",
        label: "Expiration Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Expiration Date",
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "expirationDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "itemName",
        fieldName: "itemName",
        type: "text",
        placeholder: "Item Name",
        value: "",
        label: "Item Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Item Name",
        derivedValue: "itemName=item Name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "itemName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "itemName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "device_id",
        fieldName: "device_id",
        type: "text",
        placeholder: "Device Id",
        value: "",
        label: "Device Id",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "device_id=device_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "device_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name: "deviceName",
      //   fieldName: "deviceName",
      //   type: "text",
      //   placeholder: "Device Name",
      //   value: "",
      //   label: "Device Name",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "deviceName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "deviceName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },
      // {
      //   name: "deviceLocation",
      //   fieldName: "deviceLocation",
      //   type: "text",
      //   placeholder: "Device Location",
      //   value: "",
      //   label: "Device Location",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "deviceLocation=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "deviceLocation",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },
   
      {
        name: "mac_id",
        fieldName: "mac_id",
        type: "text",
        placeholder: "Mac Id",
        value: "",
        label: "Mac Id",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "mac_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "mac_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name: "location_id",
      //   fieldName: "location_id",
      //   type: "text",
      //   placeholder: "Location Id",
      //   value: "",
      //   label: "Location Id",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "location_id=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "location_id",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },
      // {
      //   name: "locationName",
      //   fieldName: "locationName",
      //   type: "text",
      //   placeholder: "Location Name",
      //   value: "",
      //   label: "Location Name",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "locationName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "locationName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },

      {
        name: "type",
        fieldName: "type",
        type: "radio",
        placeholder: "Type",
        value: "cart",
        label: "Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Cart", value: "cart", id: "car" },
          { label: "Others", value: "others", id: "oth" },
        ],
        fieldType: "radio",
        filterElement: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "cart": ["facilityName", "storageUnitName", "expirationDate"] }, { "others": ["itemName", 'expirationDate'] }],
      },
      {
        name: "facilityName",
        fieldName: "facilityName",
        type: "relateAutoComplete",
        placeholder: "Facility Name",
        value: "",
        label: "Facility Name",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "facilityName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "facilityName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["facilityName"],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "storageUnitName",
        fieldName: "storageUnitName",
        type: "relateAutoComplete",
        placeholder: "StorageUnit Name",
        value: "",
        label: "StorageUnit Name",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "storageUnitName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "storageUnitName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1009,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "storageUnitName",
        hasDependencyField: "facilityName",
        fieldForKey: "facilityId",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["storageUnitName"],
        controllerName: "Storage Units",
        searchApi: "storageUnits",
        apiResponseKey: 'storageunits',
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "itemName",
        fieldName: "itemName",
        type: "text",
        placeholder: "Item Name",
        value: "",
        label: "Item Name",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "itemName=item Name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "itemName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "expirationDate",
        fieldName: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        value: "",
        label: "Expiration Date",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
     
     
      // {
      //   name: "accessName",
      //   fieldName: "accessName",
      //   type: "relateAutoComplete",
      //   placeholder: "Access Point Name",
      //   value: "",
      //   label: "Access Point Name",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "accessName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "accessName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: 1008,
      //   isClickable: "",
      //   isAddToIndex: "",
      //   isBulkUploadField: false,
      //   searchField: "name",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: ["name"],
      //   controllerName: "accessName",
      //   searchApi: apiCalls.accessPoints,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: false,
        isBulkUploadField: true,
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "device_id",
        fieldName: "device_id",
        type: "text",
        placeholder: "Device Id",
        value: "",
        label: "Device Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Device Id",
        derivedValue: "device_id=device_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "device_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "device_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "deviceName",
        fieldName: "deviceName",
        type: "text",
        placeholder: "Device Name",
        value: "",
        label: "Device Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Device Name",
        derivedValue: "deviceName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "deviceName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deviceName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "deviceLocation",
        fieldName: "deviceLocation",
        type: "text",
        placeholder: "Device Location",
        value: "",
        label: "Device Location",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Device Location",
        derivedValue: "deviceLocation=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "deviceLocation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deviceLocation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "mac_id",
        fieldName: "mac_id",
        type: "text",
        placeholder: "Mac Id",
        value: "",
        label: "Mac Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Mac Id",
        derivedValue: "mac_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "mac_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "mac_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "location_id",
        fieldName: "location_id",
        type: "text",
        placeholder: "Location Id",
        value: "",
        label: "Location Id",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Location Id",
        derivedValue: "location_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "location_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "location_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locationName",
        fieldName: "locationName",
        type: "text",
        placeholder: "Location Name",
        value: "",
        label: "Location Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Location Name",
        derivedValue: "locationName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "locationName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "locationName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.deviceManagements}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  console.log('RolePermissions' , RolePermissions)
  const isEditPermission = RolePermissions["Device Managements"] === "Edit" ? true : true;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "SendEmail",
      options: [
        {
          label: "Send Email",
          value: "Send Email",
          show: rolePermission?.actions?.includes("Update"),
          multiple: false,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='DeviceManagements'
          // apiUrl={apiCalls.DeviceManagements}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.deviceManagements}
          globalSearch={
            "Name"
          }
          displayName="Device Managements"
          type="DeviceManagements"
          routeTo={apiCalls.deviceManagements}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.deviceManagements?.toLowerCase()}
          apiUrl={apiCalls.deviceManagements}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="deviceManagements"
          apiUrl={apiCalls.deviceManagements}
        />
      ) : null}
    </span>
  );
};

export default DeviceManagements;
